import axios from 'axios';
import {
  DiskMetrics,
  LogBasedMetricsResponse,
  SparkDriverMemoryResponse,
} from 'types/types';
import { API_URL } from 'utils/http';

export async function getSparkMemoryMetrics(fromDate: number, toDate: number) {
  try {
    const res = await axios.get<SparkDriverMemoryResponse>(
      `${API_URL}/metrics/memory_usage_limit`,
      {
        params: {
          startTimestamp: fromDate,
          endTimestamp: toDate,
        },
      }
    );
    return res;
  } catch (error) {
    console.error('Error fetching memory usage and limits:', error);
    throw error;
  }
}

export async function getRunningJobsMetrics(fromDate: number, toDate: number) {
  try {
    const res = await axios.get<LogBasedMetricsResponse>(
      `${API_URL}/metrics/number-running-jobs`,
      {
        params: {
          startTimestamp: fromDate,
          endTimestamp: toDate,
        },
      }
    );
    return res;
  } catch (error) {
    console.error('Error fetching number of running jobs:', error);
    throw error;
  }
}

export async function getMaxMemoryMetrics(fromDate: number, toDate: number) {
  try {
    const res = await axios.get<LogBasedMetricsResponse>(
      `${API_URL}/metrics/max_memory`,
      {
        params: {
          startTimestamp: fromDate,
          endTimestamp: toDate,
        },
      }
    );
    return res;
  } catch (error) {
    console.error('Error fetching number of running jobs:', error);
    throw error;
  }
}

export async function getUsedMemoryMetrics(fromDate: number, toDate: number) {
  try {
    const res = await axios.get<LogBasedMetricsResponse>(
      `${API_URL}/metrics/used_memory`,
      {
        params: {
          startTimestamp: fromDate,
          endTimestamp: toDate,
        },
      }
    );
    return res;
  } catch (error) {
    console.error('Error fetching number of running jobs:', error);
    throw error;
  }
}

export async function getDiskMetrics(fromDate: number, toDate: number) {
  try {
    const res = await axios.get<DiskMetrics>(`${API_URL}/metrics/disk`, {
      params: {
        startTimestamp: fromDate,
        endTimestamp: toDate,
      },
    });
    return res;
  } catch (error) {
    console.error('Error fetching disk metrics:', error);
    throw error;
  }
}

export async function getQueuedJobsMetrics(fromDate: number, toDate: number) {
  try {
    const res = await axios.get<LogBasedMetricsResponse>(
      `${API_URL}/metrics/number-queued-jobs`,
      {
        params: {
          startTimestamp: fromDate,
          endTimestamp: toDate,
        },
      }
    );
    return res;
  } catch (error) {
    console.error('Error fetching number of queued of jobs metrics:', error);
    throw error;
  }
}

export async function getReservedMemory(fromDate: number, toDate: number) {
  try {
    const res = await axios.get<LogBasedMetricsResponse>(
      `${API_URL}/metrics/reserved-memory`,
      {
        params: {
          startTimestamp: fromDate,
          endTimestamp: toDate,
        },
      }
    );
    return res;
  } catch (error) {
    console.error('Error fetching reserved memory metrics:', error);
    throw error;
  }
}
