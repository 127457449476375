export const QueryServerKeys = {
  SIGN_IN: 'sign-in',
  LISTS: {
    GET_SCHEMAS_AND_TABLES: 'get-schemas-and-tables',
  },
  JOBS: {
    GET_STATUS_COUNTS: 'get-status-counts',
    GET_ALL_JOBS_PAGINATED: 'get-all-jobs-paginated',
    GET_TABLE_DETAILS_JOBS_PAGINATED: 'get-table-details-jobs-paginated',
    GET_JOB_LOGS: 'get-job-logs',
    GET_JOB_PROPERTIES: 'get-job-properties',
    GET_JOB_CODE: 'get-job-code',
    GET_JOB_SIGNED_URL: 'get-job-signed-url',
    GET_JOBS_CHART: 'get-jobs-chart',
    GET_JOB_METRICS: 'get-job-metrics',
  },
  CLUSTER: {
    GET_CLUSTER_INFO: 'get-cluster-info',
    SET_CLUSTER_CONFIG: 'set-cluster-config',
    GET_CLUSTER_CONFIG: 'get-cluster-config',
  },
  METRICS: {
    GET_DISK: 'get-disk-metrics',
    GET_NUMBER_OF_RUNNING_JOBS: 'get-number-of-running-jobs',
    GET_USED_MEMORY: 'get-used-memory',
    GET_MAX_MEMORY: 'get-max-memory',
    GET_NUMBER_OF_QUEUED_JOBS: 'get-number-of-queued-jobs',
    GET_RESERVED_MEMORY: 'get-reserved-memory',
  },
  CONNECT: {
    GET_CONNECT_CONFIGURATION: 'get-connect-configurations',
    ENABLE_CHIDORI_CONNECT: 'enable-chidori-connect',
    DISABLE_CHIDORI_CONNECT: 'disable-chidori-connect',
    GET_AUTH_FILE: 'get-auth-file',
    GET_CONNECT_CAN_RUN: 'get-connect-can-run',
  },
};
