import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { convertTimestamp } from 'utils/date';
import { green, blue, gray, red } from '@ant-design/colors';
import { useIntl } from 'react-intl';
import { ReservedMemoryDetail } from 'types/types';

interface TimeSeriesProp {
  chartType: 'smooth' | 'straight' | 'stepline' | 'linestep' | 'monotoneCubic';
  chartTitle: string;
  yAxisFormatter: (val: number) => string;
  yAxisTitle: string;
  toolTipFormatter: (val: number) => string;
  seriesData: { name: string; data: number[]; zIndex?: number }[];
  timeData?: string[];
  reservedMemoryDetails?: ReservedMemoryDetail[];
}

const TimeSeriesChart: React.FC<TimeSeriesProp> = ({
  chartType,
  chartTitle,
  yAxisFormatter,
  yAxisTitle,
  toolTipFormatter,
  seriesData,
  timeData,
  reservedMemoryDetails,
}) => {
  const isSinglePoint = seriesData.every((series) => series.data.length === 1);
  const intl = useIntl();

  const [chartOptions] = useState<ApexOptions>({
    chart: {
      type: 'line',
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: 'zoom',
      },
    },
    colors: [blue[6], green[6], red[6]],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: chartType,
      width: isSinglePoint ? 0 : 3,
    },
    markers: {
      size: isSinglePoint ? 8 : 0,
    },
    legend: {
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    grid: {
      padding: {
        top: 60,
        bottom: 40,
        left: 60,
        right: 40,
      },
    },
    title: {
      text: chartTitle,
      align: 'left',
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: gray[13],
      },
    },
    xaxis: {
      type: 'datetime',
      categories: timeData,
      labels: {
        formatter: (value: string) => {
          const isoString = new Date(parseInt(value)).toISOString();
          const { dateFormatted, timeFormatted } = convertTimestamp(isoString);
          return `${dateFormatted} ${timeFormatted}`;
        },
      },
    },
    yaxis: {
      labels: {
        formatter: yAxisFormatter,
      },
      title: {
        text: yAxisTitle,
      },
    },
    tooltip: {
      shared: false,
      y: {
        formatter: (val: number, { seriesIndex, dataPointIndex, w }) => {
          const seriesName = w.config.series[seriesIndex].name;

          if (
            seriesName === intl.formatMessage({ id: 'metrics.reservedMemory' })
          ) {
            const details = reservedMemoryDetails?.[dataPointIndex];
            console.log("INDEX", dataPointIndex)

            console.log("INSIDE", details)

            if (details?.JobMemoryPairs && details.JobMemoryPairs.length > 0) {
              const jobsInfo = details.JobMemoryPairs.map(
                (job) =>
                  `JobID: ${job.JobID}, Reserved: ${job.ReservedMemory} MB`
              ).join('<br/>');

              return `${val} MB <br/>${jobsInfo}`;
            }
            return `${val} MB <br/><strong>No Reserved Jobs</strong>`;
          }
          return toolTipFormatter(val);
        },
      },
    },

    fill: {
      type: 'none',
    },
  });

  return (
    <ReactApexChart
      options={chartOptions}
      series={seriesData}
      type="line"
      height={'650px'}
    />
  );
};

export default TimeSeriesChart;
