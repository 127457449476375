import { message } from 'antd';
import { QueryServerKeys } from 'QueryServerKeys';
import { useQuery } from 'react-query';
import * as MetricsService from 'services/metrics';

// export function useGetSparkMemoryMetrics({
//   fromDate,
//   toDate,
//   formatedRange,
// }: {
//   fromDate: number;
//   toDate: number;
//   formatedRange: string;
// }) {
//   const queryOptions = useQuery(
//     [QueryServerKeys.METRICS.GET_SPARK_DRIVER_MEMORY_USAGE, formatedRange],
//     async () => {
//       const res = await MetricsService.getSparkMemoryMetrics(fromDate, toDate);

//       return res?.data;
//     },
//     {
//       onError(err) {
//         message.error('Error fetching spark driver memory metrics');
//       },
//       refetchOnWindowFocus: false,
//     }
//   );

//   return queryOptions;
// }

export function useGetDiskMetrics({
  fromDate,
  toDate,
  formatedRange,
}: {
  fromDate: number;
  toDate: number;
  formatedRange: string;
}) {
  const queryOptions = useQuery(
    [QueryServerKeys.METRICS.GET_DISK, formatedRange],
    async () => {
      const res = await MetricsService.getDiskMetrics(fromDate, toDate);

      return res?.data;
    },
    {
      onError(err) {
        message.error('Error fetching disk metrics');
      },
      refetchOnWindowFocus: false,
    }
  );

  return queryOptions;
}

export function useGetNumberOfRunningJobs({
  fromDate,
  toDate,
  formatedRange,
}: {
  fromDate: number;
  toDate: number;
  formatedRange: string;
}) {
  const queryOptions = useQuery(
    [QueryServerKeys.METRICS.GET_NUMBER_OF_RUNNING_JOBS, formatedRange],
    async () => {
      const res = await MetricsService.getRunningJobsMetrics(fromDate, toDate);

      return res?.data;
    },
    {
      onError(err) {
        message.error('Error fetching number of running jobs ');
      },
      refetchOnWindowFocus: false,
    }
  );

  return queryOptions;
}

export function useGetUsedMemory({
  fromDate,
  toDate,
  formatedRange,
}: {
  fromDate: number;
  toDate: number;
  formatedRange: string;
}) {
  const queryOptions = useQuery(
    [QueryServerKeys.METRICS.GET_USED_MEMORY, formatedRange],
    async () => {
      const res = await MetricsService.getUsedMemoryMetrics(fromDate, toDate);

      return res?.data;
    },
    {
      onError(err) {
        message.error('Error fetching number of running jobs ');
      },
      refetchOnWindowFocus: false,
    }
  );

  return queryOptions;
}

export function useGetMaxMemory({
  fromDate,
  toDate,
  formatedRange,
}: {
  fromDate: number;
  toDate: number;
  formatedRange: string;
}) {
  const queryOptions = useQuery(
    [QueryServerKeys.METRICS.GET_MAX_MEMORY, formatedRange],
    async () => {
      const res = await MetricsService.getMaxMemoryMetrics(fromDate, toDate);

      return res?.data;
    },
    {
      onError(err) {
        message.error('Error fetching number of running jobs ');
      },
      refetchOnWindowFocus: false,
    }
  );

  return queryOptions;
}

export function useGetNumberOfQueuedJobs({
  fromDate,
  toDate,
  formatedRange,
}: {
  fromDate: number;
  toDate: number;
  formatedRange: string;
}) {
  const queryOptions = useQuery(
    [QueryServerKeys.METRICS.GET_NUMBER_OF_QUEUED_JOBS, formatedRange],
    async () => {
      const res = await MetricsService.getQueuedJobsMetrics(fromDate, toDate);

      return res?.data;
    },
    {
      onError(err) {
        message.error('Error fetching queued jobs');
      },
      refetchOnWindowFocus: false,
    }
  );

  return queryOptions;
}

export function useGetReservedMemory({
  fromDate,
  toDate,
  formatedRange,
}: {
  fromDate: number;
  toDate: number;
  formatedRange: string;
}) {
  const queryOptions = useQuery(
    [QueryServerKeys.METRICS.GET_RESERVED_MEMORY, formatedRange],
    async () => {
      const res = await MetricsService.getReservedMemory(fromDate, toDate);

      return res?.data;
    },
    {
      onError(err) {
        message.error('Error fetching reserved memory ');
      },
      refetchOnWindowFocus: false,
    }
  );

  return queryOptions;
}
